
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Opeartion'
})
export default class Operation extends Vue {
  private tableData = []
  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private types = ['入库', '领用', '退还', '调拨', '盘库', '盘亏', '盘盈', '处置', '编辑']
  private typeValue = ['1', '2', '3', '4', '5', '53', '54', '6', '7']

  get assetId () {
    return this.$route.params.assetId as string
  }

  created () {
    this.getData()
  }

  goPath (row: { orderId: string; orderType: string; projectId: string }) {
    // 1入库
    if (row.orderType === '1') {
      this.$router.push({ name: 'AssestWarehousingDetail', params: { orderId: row.orderId } })
    }
    if (row.orderType === '2') {
      // 领用
      this.$router.push({ name: 'AssetsRequisitionDetail', params: { orderId: row.orderId } })
    }
    if (row.orderType === '3') {
      // 退还
      this.$router.push({ name: 'AssetsRefundDetail', params: { orderId: row.orderId }, query: { isRefund: '2' } })
    }
    if (row.orderType === '4') {
      // 调拨
      this.$router.push({ name: 'assetTransferDetail', params: { orderId: row.orderId } })
    }
    // 盘库 盘亏 盘盈
    if (['5', '53', '54'].includes(row.orderType)) {
      // 盘库
      this.$router.push({ name: 'inventoryDetail', query: { orderId: row.orderId, projectId: row.projectId, detail: '1' } })
    }
    if (row.orderType === '6') {
      // 处置
      this.$router.push({ name: 'disposalDetail', query: { orderId: row.orderId } })
    }
  }

  getState (name: string) {
    const index = this.typeValue.findIndex(item => {
      return item === name
    })
    return this.types[index]
  }

  getData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetOrderByPage, {
      assetId: this.assetId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    })
  }
}
